import React from "react"

function InstagramGallery() {
  return (
    <div>
      <div className="instagramTitleContainer">
        <h1> Instagram </h1>
        <a
          href="https://yahoo.com"
          onclick="location.href='https://google.com';"
        >
          {" "}
        </a>
      </div>

      <div className="instagramContainer">
        <iframe
          title="Ig"
          src="https://snapwidget.com/embed/962666"
          class="snapwidget-widget"
          allowtransparency="true"
          frameborder="0"
          scrolling="true"
          style={{
            border: "none",
            overflow: "scroll",
            width: "810px",
            height: "2000px",
          }}
        ></iframe>
      </div>
    </div>
  )
}

export default InstagramGallery
